import { postBodyRequest, getRequest, exportRequest } from '@/utils/axios'

export function orgProfit (params) {
  return postBodyRequest('/trans/finance/profit/org', params)
}

export function saleEmpProfit (id, params) {
  return postBodyRequest(`/trans/finance/profit/${id}/emp`, params)
}

export function saleEmpProfitDetail (empId, params) {
  return postBodyRequest(`/trans/finance/profit/emp/${empId}`, params)
}

export function transProfit (params) {
  return postBodyRequest('/trans/finance/profit/trans', params)
}

export function transProfitUpdate (params) {
  return postBodyRequest('/trans/finance/profit/archive/update', params)
}

export function transProfitArchiveTree (params) {
  return postBodyRequest('/trans/finance/profit/archive/trans/tree', params)
}

export function transProfitArchiveList (params) {
  return postBodyRequest('/trans/finance/profit/archive/trans/list', params)
}

export function transProfitArchiveDetail (archiveId, params) {
  return getRequest(`/trans/finance/profit/archive/${archiveId}`, params)
}

export function updateProfitArchive (params) {
  return postBodyRequest('/trans/finance/profit/archive/update', params)
}

export function exportProfitArchive (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/export', params, fileName)
}

export function exportEscrowArchive (params, fileName) {
  return exportRequest('/trans/finance/profit/escrow/export', params, fileName)
}

export function exportProfitDetailArchive (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/detail/export', params, fileName)
}

export function saveProfitArchive (archiveId, params) {
  return postBodyRequest(`/trans/finance/profit/archive/${archiveId}/save`, params)
}

export function archiveProfitArchive (archiveId, params) {
  return postBodyRequest(`/trans/finance/profit/archive/${archiveId}/archive`, params)
}

export function saleProfitTree (params) {
  return postBodyRequest('/trans/finance/profit/archive/sale/tree', params)
}

export function saleProfitList (params) {
  return postBodyRequest('/trans/finance/profit/archive/sale/profitList', params)
}

export function saleProfitExport (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/emp/export', params, fileName)
}

export function saleProfitListUpdate (params) {
  return postBodyRequest('/trans/finance/profit/archive/sale/update', params)
}
// 财务-每个办事处月利润明细-导出
export function archiveDetailExport (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/detail/export', params, fileName)
}
